<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    MULTI-USER TOKEN PAGE
                </v-card-title>
                <v-card-subtitle>
                    Generate PINS and Access Tokens to Teachers, Parents and Students to access results
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                        <v-col cols="12" v-if="newform">
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                            <v-select
                                                v-model="form.user_type"
                                                outlined
                                                clearable
                                                :items="['Teacher', 'Student']"
                                                :rules="[v => !!v || 'Select One']"
                                                label="Select User Access Type"
                                                @change="fetchFormRecords"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        v-if="form.user_type === 'Teacher' || form.user_type === 'Parent'"
                                        >
                                            <v-text-field
                                                outlined
                                                clearable
                                                v-model="form.fullname"
                                                :label="form.user_type +' Fullname'"
                                                :rules="[v => !!v || 'Enter name']"
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                            <v-select
                                                outlined
                                                clearable
                                                :items="classes"
                                                item-text="class"
                                                item-value="class_id"
                                                :label="form.user_type == 'Teacher' ? 'Assign Class to Teacher' : 'Student Class'"
                                                v-model="form.class_id"
                                                :rules="[v => !!v || 'Class need to be selected!']"
                                                required
                                                @change="fetchStudents()"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="12"
                                        v-if="form.user_type === 'Student'"
                                        >
                                            <v-select
                                                outlined
                                                clearable
                                                :items="students"
                                                item-text="name"
                                                item-value="scs_id"
                                                label="Select Student"
                                                v-model="form.scs_id"
                                                :rules="[v => !!v || 'Student need to be selected!']"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        v-if="form.user_type === 'Parent'"
                                        >
                                            <v-select
                                                outlined
                                                clearable
                                                :items="students"
                                                item-text="name"
                                                item-value="scs_id"
                                                label="Select Wards belonging to Parent"
                                                v-model="form.scs_id"
                                                :rules="[v => !!v || 'Student(s) need to be selected!']"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-alert text type="info" v-if="form.user_type === 'Teacher'">This Teacher will have access to this Class' scores and results</v-alert>
                                        <v-alert text type="info" v-if="form.user_type === 'Parent'">This Parent will have access to the selected students results</v-alert>
                                        <v-alert text type="info" v-if="form.user_type === 'Student'">This Student will have access to his/her results</v-alert>

                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord"
                                        >
                                            Generate Access Token
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Cancel
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <v-col cols="12" v-else>
                            <premium-info v-if="school.plan == 'Free'"/>
                            <v-btn tile color="primary" @click="reset" v-else class="mt-2 mb-2 noprint">
                                Generate New PIN
                            </v-btn>
                            <info-loader-2 v-if="fetchingContent"/>
                            <v-row v-else>
                                <v-col cols="12">
                                    <v-alert type="info" text>To ensure the security of data, PINS and Serial number generated will expire after 30 days of generating them or after the first 300 uses. You can generate another once expired. You are responsible for securing the PINs and KEYs you generate.</v-alert>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="search"
                                        outlined
                                        clearable
                                        :items="['Teacher', 'Student']"
                                        placeholder="Search"
                                        @change="getRecords"
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" lg="4" v-for="(record, i) in records" :key="i">
                                    <v-card elevation="2" color="#fefefe">
                                        <v-card-title class="subtitle-2 purple--text">
                                            <span v-if="record.user_type == 'Teacher'">Teacher - {{record.clasx}} Class</span>
                                            <span v-if="record.user_type == 'Student'">Student - {{record.student_fullname}}</span>
                                            <span v-if="record.user_type == 'Parent'"> Parent - {{record.fullname}}</span>
                                        </v-card-title>
                                        <v-card-text class="font-weight-black">
                                            <span>KEY: <span class="green--text button">{{record.key}}</span></span><br>
                                            <span>PIN: <span class="green--text">{{record.pin}}</span></span><br>
                                            <span>EXPIRE: <span class="red--text">{{record.expire_at}}</span></span><br>
                                            Use @ <v-chip>www.results.mysch.ng/access</v-chip><br>
                                            <span>Teacher Access</span>
                                        </v-card-text>
                                        <v-card-actions class="noprint">
                                            <v-btn @click="deleteRecord(record)" fab color="error" x-small
                                            ><v-icon dark> mdi-trash-can-outline </v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-btn tile color="yellow" v-if="records.length > 0" class="mt-3 noprint" href="javascript:window.print()">
                                Print
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';
import InfoLoader2 from '../../components/InfoLoader2.vue';
import PremiumInfo from '../../components/PremiumInfo.vue';
  export default {
    name: 'GenerateTokens',
    components: {
        HeaderVue,
        InfoLoader2,
        PremiumInfo
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        search: 0,
        form:{},
        records:[],
        classes:[],
        students:[],
        valid: true,
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.form = {}
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/multiaccess?school_id='+this.school.school_id+'&user_type='+this.search,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
            })
            .then((response) => {
                this.records =  response.data.data   
                this.fetchingContent = false      
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Trya again later!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/multiaccess?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/multiaccess/'+record.access_token+'?school_id='+this.school.school_id,{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        },
        fetchClasses () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.classes =  response.data.data          
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        fetchStudents (){
            axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id+'&class_id='+this.form.class_id,
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then((response) => {
                this.students =  response.data.data   
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        fetchFormRecords(){
            this.form.fullname = null,
            this.form.scs_id = null,
            this.form.class_id = null,
            this.fetchClasses(),
            this.fetchStudents()
        }
    },
  }
</script>

<style>
@media print{
    .noprint {
        display: none !important;
    }
}
</style>
